import React from "react";

import Head from "next/head";
import { useRouter } from "next/router";

import { Column, Grid, Row } from "@/components/grid";
import Layout from "@/components/layout";
import { Spacer } from "@/components/layout/components";
import { Slot } from "@/components/slot";
import { Typography } from "@/components/typography/typography";
import { Visual } from "@/components/visual";
import type { Locale } from "@/contentful/content-model/types";
import { TypographyVariant } from "@/theme";
import type {
	BlogPost,
	BusinessInfoCollection,
	NavigationCollection,
	Page,
	Person,
	SubPage,
	TextCollection,
} from "@/types/contentful-api";

import type { PageTemplateProps } from "./types";

export const Template: React.FC<
	PageTemplateProps<{
		page: Page;
		entryListCollection: Record<string, SubPage[] | BlogPost[] | Person[]>;
		textCollection: TextCollection;
		headerNavigation: NavigationCollection;
		footerNavigation: NavigationCollection;
		businessInfoCollection: BusinessInfoCollection;
	}>
> = ({ data, url }) => {
	const { locale } = useRouter();

	const { page, textCollection, entryListCollection } = data;
	const {
		slotsCollection: { items: slots },
		headline,
		keyVisual,
		meta,
		sys: { publishedAt },
	} = page;

	return (
		<Layout translucentHeader data={data} meta={meta} url={url}>
			<Head>
				<meta name="last-publish" content={publishedAt} />
			</Head>
			{keyVisual && <Visual data={keyVisual} textCollection={textCollection} />}
			{headline && !keyVisual && (
				<Grid>
					<Row>
						<Column>
							<Typography component="h1" variant={TypographyVariant.headlineSerifLG}>
								{headline}
							</Typography>
						</Column>
					</Row>
				</Grid>
			)}
			{slots.map(slot => (
				<Slot
					key={slot.sys.id}
					locale={locale as Locale}
					// Todo: rename `data` prop to something more specific
					data={entryListCollection[`query_${slot.sys.id}`]}
					textCollection={data.textCollection}
					{...slot}
				/>
			))}
			<Spacer spacing="xxl" />
		</Layout>
	);
};
